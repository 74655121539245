import { Dialog } from '@headlessui/react';
import clsx from 'clsx';
import { useRouter } from 'next/navigation';

import Popup from '../../popup';

const ConfirmSignInModal = ({
  loading = false,
  isOpen,
  setIsOpen,
  title,
  description,
  size = 'md',
  type = 'delete',
  forceClose = false,
}: {
  loading?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  description: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  type?: string;
  forceClose?: boolean;
}) => {
  const router = useRouter();
  return (
    <Popup
      forceClose={forceClose}
      bgColor="bg-grey-purple-white"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size={size}
    >
      <Dialog.Title
        as="h3"
        className={clsx(
          'text-sm font-semibold leading-6',
          type === 'annotate' ? 'text-dark-navy-blue' : 'text-rusty-red'
        )}
      >
        {title}
      </Dialog.Title>
      <div className="mt-2">
        <p className="whitespace-pre-line text-sm text-gray-900">
          {description}
        </p>
      </div>

      <div className="mt-4 flex flex-row items-center justify-end gap-x-3">
        <button
          type="button"
          className="inline-flex justify-center rounded-full border border-dark-navy-blue/10 px-4 py-1 text-sm font-normal focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
          onClick={() => router.push('/sign-up')}
        >
          Sign Up
        </button>
        <button
          disabled={loading}
          type="button"
          className={clsx(
            'inline-flex justify-center rounded-full border border-dark-navy-blue/10 bg-vivid-orange px-4 py-1 text-sm font-normal text-grey-purple-white hover:bg-vivid-orange/90 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2'
          )}
          onClick={() => router.push('/sign-in')}
        >
          Sign In
        </button>
      </div>
    </Popup>
  );
};

export default ConfirmSignInModal;
