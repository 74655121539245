import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Button } from '@nextui-org/react';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const navbarList = [
  {
    link: '/',
    name: 'Home',
  },
  {
    link: '/genres',
    name: 'Genres',
  },
];

export default function NavbarModal({
  isOpen,
  setIsOpen,
  forceClose = false,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  forceClose?: boolean;
}) {
  const router = usePathname();
  const closePopup = () => {
    if (forceClose) {
      return;
    }
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 bg-black/20 backdrop-blur-md transition-opacity" />
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed left-0 top-0 z-60 overflow-y-auto"
          onClose={closePopup}
        >
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                className={clsx(
                  'inline-block h-screen w-80 transform overflow-hidden rounded-r-2xl bg-white/90 p-4 text-left align-middle shadow-xl backdrop-blur-md transition-all'
                )}
              >
                <Dialog.Title
                  as="h3"
                  className={clsx('flex w-full items-center justify-end')}
                >
                  <Button
                    isIconOnly
                    onClick={() => setIsOpen(false)}
                    className="bg-transparent text-black hover:text-aerospace"
                  >
                    <XMarkIcon width={30} height={30} />
                  </Button>
                </Dialog.Title>
                <div className="flex flex-col gap-y-2">
                  <LazyLoadImage
                    src="/images/svg/indiemunkey-color.svg"
                    className="mx-auto w-40"
                    effect="blur"
                  />
                  <div className="flex flex-col gap-y-3">
                    {navbarList.map((item) => (
                      <Link
                        href={item.link}
                        key={item.link}
                        onClick={() => setIsOpen(false)}
                      >
                        <p
                          className={clsx(
                            item.link === router
                              ? 'border-b-2 border-aerospace text-vivid-orange'
                              : 'text-black',
                            'text-lg font-medium hover:text-aerospace'
                          )}
                        >
                          {item.name}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
