import { useMutation } from '@tanstack/react-query';

import type { UpdateUserFormData } from '@/interfaces/user';
import http from '@/libs/http';

export const updateMe = async (
  accessToken: string | undefined,
  payload: UpdateUserFormData
) => {
  const response = await http
    .patch(`auth/me`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: payload,
    })
    .json();

  return response;
};

export const useUpdateMe = () => {
  return useMutation({
    mutationKey: ['updateMe'],
    mutationFn: ({
      accessToken,
      payload,
    }: {
      accessToken: string | undefined;
      payload: UpdateUserFormData;
    }) => updateMe(accessToken, payload),
  });
};
