import { Button } from '@nextui-org/react';
import Link from 'next/link';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Env } from '@/libs/Env.mjs';

const listLinkConnectUs = [
  {
    name: 'instagram',
    url: Env.NEXT_PUBLIC_INTARGRAM_URL,
    image: '/images/svg/instagram-icon.svg',
  },
  {
    name: 'discord',
    url: Env.NEXT_PUBLIC_DISCORD_URL,
    image: '/images/svg/discord-icon.svg',
  },
];

const listTerms = [
  {
    name: 'About Us',
    url: '/about-us',
  },
  {
    name: 'Content Policy',
    url: '/terms',
  },
];

const Footer = () => {
  return (
    <div className="relative flex w-full flex-col items-center justify-center bg-transparent">
      <LazyLoadImage
        src="/images/svg/border-footer.svg"
        className="h-10 w-full scale-100"
      />
      <div className="flex w-full flex-col items-center justify-center gap-y-8 bg-black py-20">
        <Link href="/" className="cursor-pointer">
          <LazyLoadImage
            src="/images/svg/indiemunkey-white.svg"
            className="w-52"
            effect="blur"
          />
        </Link>
        <div className="flex flex-row items-center gap-x-1">
          {listLinkConnectUs.map((item) => (
            <Button
              key={item.name}
              as={Link}
              target="_blank"
              href={item.url}
              isIconOnly
              className="bg-transparent"
            >
              <LazyLoadImage src={item.image} className="w-7" effect="blur" />
            </Button>
          ))}
        </div>
        <div className="flex flex-row items-center gap-x-3">
          {listTerms.map((item) => (
            <Button
              key={item.name}
              as={Link}
              className="bg-transparent text-white"
              size="md"
              href={item.url}
            >
              {item.name}
            </Button>
          ))}
        </div>
      </div>
      <Link
        href="//www.dmca.com/Protection/Status.aspx?ID=12745194-617c-4427-80a1-ebee874ce0a9"
        title="DMCA.com Protection Status"
        className="dmca-badge absolute bottom-8 left-0"
      >
        <LazyLoadImage
          src="https://images.dmca.com/Badges/dmca_protected_26_120.png?ID=12745194-617c-4427-80a1-ebee874ce0a9"
          alt="DMCA.com Protection Status"
          width={60}
          height={20}
          effect="blur"
        />
      </Link>
    </div>
  );
};
export default Footer;
