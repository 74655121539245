'use client';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { usePathname } from 'next/navigation';
import { signOut, useSession } from 'next-auth/react';
import { useEffect, useMemo, useState } from 'react';

import ConfirmSignInModal from '@/components/comic/auth/confirm-sign-in-modal';
import UpdateUserDateOfBirthScreen from '@/components/comic/auth/update-user-date-of-birth';
import Loading from '@/components/comic/loading';
import BackToTopButton from '@/components/layout/back-to-top-button';
import Footer from '@/components/layout/footer';
import Navbar from '@/components/layout/navbar';
import NavbarModal from '@/components/layout/navbar/navbar-modal';
import { useLayout } from '@/components/providers/LayoutProvider';
import useWindowSize from '@/libs/hooks/use-window-size';

import NotAvailableOnMobile from '../not-available-on-mobile';

export default function DefaultLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { loading } = useLayout();
  const path = usePathname();
  const { isMobile } = useWindowSize();
  const { data: session, status } = useSession();

  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmSign, setIsConfirmSign] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      status === 'authenticated' &&
      (!session.access || dayjs().isAfter(dayjs(session.expires)))
    ) {
      signOut();
    }
  }, [session, status]);

  const conditionalContent = useMemo(() => {
    if (path.includes('public-title') && isMobile) {
      return <NotAvailableOnMobile />;
    }
    return null;
  }, [path, isMobile]);

  if (conditionalContent) return conditionalContent;

  if (session && session.user && !session.user.dateOfBirth) {
    return <UpdateUserDateOfBirthScreen />;
  }

  return (
    <div>
      {conditionalContent || (
        <>
          <div className="h-full w-full overflow-hidden">
            <div
              className={clsx(
                'fixed left-0 top-0 z-30 w-full overflow-hidden py-1 drop-shadow-sm',
                isScrolled ? 'bg-white/95 backdrop-blur' : 'bg-white'
              )}
            >
              <Navbar
                setIsOpen={setIsOpen}
                setIsConfirmSign={setIsConfirmSign}
              />
            </div>
            <div className="z-20 min-h-screen w-screen overflow-x-hidden">
              {children}
            </div>
          </div>
          <BackToTopButton />
          <Footer />
          <NavbarModal isOpen={isOpen} setIsOpen={setIsOpen} />
          <ConfirmSignInModal
            title="Confirm"
            description="Sign in to use"
            setIsOpen={setIsConfirmSign}
            isOpen={isConfirmSign}
          />
          <Loading loading={loading} />
        </>
      )}
    </div>
  );
}
