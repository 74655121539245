import { useQuery } from '@tanstack/react-query';

import type { ApiResponse } from '@/interfaces/api-response';
import type { User } from '@/interfaces/user';
import http from '@/libs/http';

const fetchMe = async (
  accessToken: string | undefined
): Promise<ApiResponse<User>> => {
  const response = (await http
    .get(`auth/me`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .json()) as ApiResponse<User>;

  return response;
};

const useMe = (accessToken: string | undefined) => {
  return useQuery({
    queryKey: ['me'],
    queryFn: () => fetchMe(accessToken),
    enabled: !!accessToken,
  });
};

export { fetchMe, useMe };
