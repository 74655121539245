import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { Button } from '@nextui-org/react';
import { useEffect, useState } from 'react';

const BackToTopButton = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const jumpToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="fixed bottom-5 right-4 z-20">
      {show && (
        <Button
          isIconOnly
          radius="full"
          size="lg"
          className="bg-vivid-orange text-white"
          onClick={jumpToTop}
        >
          <ArrowUpIcon width={24} height={24} />
        </Button>
      )}
    </div>
  );
};
export default BackToTopButton;
