'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { getLocalTimeZone, today } from '@internationalized/date';
import { Button, DatePicker, Input } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import type { HTTPError } from 'ky';
import Link from 'next/link';
import { signOut, useSession } from 'next-auth/react';
import { useForm } from 'react-hook-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import type { z } from 'zod';

import { useLayoutActions } from '@/components/providers/LayoutProvider';
import { useUpdateMe } from '@/hooks/mutations/useUpdateMe';
import type { UpdateUserFormData } from '@/interfaces/user';
import { UpdateUserValidation } from '@/validations/UserValidation';

import toast from '../../toast';

const UpdateUserDateOfBirthScreen = () => {
  const { data: session, update } = useSession();
  const { setLoading } = useLayoutActions();
  const queryClient = useQueryClient();

  const { mutate: updateMe } = useUpdateMe();

  const { handleSubmit, setValue, watch } = useForm<
    z.infer<typeof UpdateUserValidation>
  >({
    resolver: zodResolver(UpdateUserValidation),
  });

  const handlerUpdateMe = handleSubmit(async () => {
    setLoading(true);

    const formattedDate =
      watch('dateOfBirth') instanceof Date
        ? dayjs(watch('dateOfBirth')).format('YYYY-MM-DD')
        : '';
    const formData: UpdateUserFormData = new FormData();
    formData.append('dateOfBirth', formattedDate);

    updateMe(
      {
        accessToken: session?.access,
        payload: formData,
      },
      {
        onSuccess: () => {
          update({
            ...session,
            user: {
              ...session?.user,
              dateOfBirth: formattedDate,
            },
          });
          queryClient.invalidateQueries({
            queryKey: ['me'],
          });

          queryClient.invalidateQueries({
            queryKey: ['user'],
          });

          toast({
            type: 'success',
            content: 'Updated successfully!',
          });
        },
        onError: async (error) => {
          if (error.name === 'HTTPError') {
            try {
              const response = await (error as HTTPError).response.json();
              const message =
                (response as { message?: string }).message ||
                'An unexpected error occurred.';

              toast({
                type: 'error',
                content: message,
              });
            } catch {
              toast({
                type: 'error',
                content:
                  'An unexpected error occurred while processing the response.',
              });
            }
          }
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
  });

  return (
    <div className="relative flex min-h-screen w-screen items-center justify-center bg-[url('/images/bg-sign-in.png')] bg-cover">
      <div className="z-20 flex w-full flex-col items-center justify-center gap-y-3 rounded-lg px-10 py-12 md:max-w-lg md:border md:border-white/25 md:bg-gray-950 md:shadow-outside md:shadow-white/35 md:backdrop-blur">
        <div className="flex w-full max-w-sm flex-col items-center justify-center gap-y-5 md:h-auto">
          <p className="text-2xl font-semibold text-black md:text-white">
            Update Your Date Of Birth
          </p>
          <form
            onSubmit={handlerUpdateMe}
            className="flex w-full flex-col gap-y-5"
          >
            <Input
              type="text"
              placeholder="Username"
              labelPlacement="outside"
              className="w-full"
              value={session?.user.userName}
              aria-label="Username"
              isReadOnly
            />
            <DatePicker
              label="Birth Date"
              onChange={(value) => {
                const nativeDate =
                  value instanceof Date ? value : value?.toDate?.('UTC');
                if (nativeDate) {
                  setValue('dateOfBirth', nativeDate);
                }
              }}
              maxValue={today(getLocalTimeZone())}
              isInvalid
              showMonthAndYearPickers
            />

            <Button type="submit" className="bg-vivid-orange text-lg">
              Update
            </Button>
            <Button
              onClick={() => signOut()}
              className="bg-transparent text-sm text-black underline underline-offset-4 md:text-white"
            >
              Sign out
            </Button>
          </form>
        </div>
      </div>
      <div className="absolute left-6 top-6 z-30">
        <Link href="/">
          <LazyLoadImage
            src="/images/svg/indiemunkey-white.svg"
            effect="blur"
            className="w-40"
          />
        </Link>
      </div>
    </div>
  );
};

export default UpdateUserDateOfBirthScreen;
