import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

export interface Item {
  link: string;
  name: string;
}

const NavbarItem = ({ item }: { item: Item }) => {
  const router = usePathname();
  const { link, name } = item;
  return (
    <Link
      href={link}
      className={clsx(
        router === link
          ? 'border-b-2 border-aerospace text-black'
          : 'text-black',
        'hover:text-aerospace'
      )}
    >
      <p className="text-xl uppercase tracking-wide">{name}</p>
    </Link>
  );
};

export default NavbarItem;
