import ky from 'ky';

import { Env } from './Env.mjs';

const http = ky.create({
  timeout: 60000,
  prefixUrl: `${Env.NEXT_PUBLIC_BACKEND_URL}/api/v1`,
  retry: 0,
});

export default http;
